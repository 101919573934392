
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import PreferenciasColuna from '@/core/components/Preferencias/ColunaTabela.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { IColumn, ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '../models/Enumeradores/ETipoArquivo';
import { ITelaComunicacao } from '@/core/models/ITelaComunicacao';
import { ITelaEstrutura } from '../models/ITelaEstrutura';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import UtilitarioMascara from '../utilitarios/UtilitarioMascara';
import { useGradeBase } from '../composables/GradeBase';
import BuscaGeral from './BuscaAvancada/BuscaGeral.vue';
import UtilitarioDispositivo from '../utilitarios/UtilitarioDispositivo';
import TituloPadrao from './Tela/TituloPadrao.vue';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';

const dados: any[] = [];

export interface IListaSelecionados {
  key: number;
}

export default defineComponent({
  name: 'TelaPadraoCrud',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    PreferenciasColuna,
    BuscaGeral,
    TituloPadrao,
  },
  emits: ['exibir-modal', 'sincroniza-tela-crud', 'registroExcluido'],
  props: {
    estrutura: {
      type: Object as () => ITelaEstrutura,
      required: true,
    },
    servicoAPI: {
      type: Object as () => IServicoBase<any>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      telaBase, montaObjetoPreferencia, salvarPreferencias,
      apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
      adicionarAtalho, removerAtalho,
    } = useTelaBase();

    const { gradeBase } = useGradeBase();

    gradeBase.colunasMobile = [
      {
        title: 'Cadastro', dataIndex: '', key: 'Cadastro', position: 0, visible: true, align: 'left',
      },
    ];

    const tela = reactive({
      dados,
      colunas: props.estrutura.colunasGrade,
      ordenacaoCampos: props.estrutura.ordenacaoPadraoGrade,
      exibirGuia: false,
      exibirHistorico: false,
      atalhoAdicionado: false,
      windowHeight: (window.innerHeight - 300),
      windowWidth: (window.innerWidth - 100),
      buscaRapida: '',
      exibirPersonalizarColunas: false,
      exibirBuscaAvancada: false,
      exibirPersonalizacaoTela: false,
      buscaAvancada: {} as IBuscaAvancada,
      paginacao: {} as IPagination,
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
      mobile: false,
    });

    if (window.innerWidth < 900) {
      tela.windowWidth = 600;
      tela.windowHeight = window.innerHeight;
    }
    telaBase.contratantesSelecionados = [];
    gradeBase.ordenacaoSelecionada = [];

    function sincronizaTelaComponentePai() {
      const telaCrud: ITelaComunicacao = {
        contratantesSelecionados: telaBase.contratantesSelecionados,
        codigosSelecionados: gradeBase.codigosSelecionados,
        ordenacaoSelecionada: gradeBase.ordenacaoSelecionada,
        filtrosAplicados: gradeBase.filtrosAplicados,
      };
      emit('sincroniza-tela-crud', telaCrud);
    }

    async function buscarDados() {
      tela.dados = [];

      gradeBase.filtrosAplicados = [];
      tela.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.valorBuscaRapida = tela.buscaRapida;
      parametrosConsulta.contratantes = telaBase.contratantesSelecionados;
      parametrosConsulta.numeroPagina = tela.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = tela.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = Number(tela.totalRegistrosComMascara);
      parametrosConsulta.ordenacao = Array<string>();

      if (tela.mobile) {
        tela.ordenacaoCampos.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });
      } else {
        gradeBase.ordenacaoSelecionada.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });

        if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
          props.estrutura.ordenacaoPadraoGrade.forEach((item) => {
            parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
          });
        }
      }

      telaBase.carregando = true;

      const listaPaginada = await props.servicoAPI.buscaAvancada(parametrosConsulta, gradeBase.filtrosAplicados);
      tela.dados = listaPaginada.dados;
      tela.paginacaoApi = listaPaginada.metaData;
      telaBase.carregando = false;
      sincronizaTelaComponentePai();
    }

    async function imprimir(tipoArquivoRelatorio: ETipoArquivo, codigoRegistro?: number) {
      gradeBase.filtrosAplicados = [];

      if (telaBase.contratantesSelecionados.length === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }
      tela.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });
      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.contratantes = telaBase.contratantesSelecionados;
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.valorBuscaRapida = tela.buscaRapida;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });
      let codigosSelecionados: number[] = [];
      if (codigoRegistro !== undefined && codigoRegistro > 0) {
        codigosSelecionados.push(codigoRegistro);
      } else {
        codigosSelecionados = gradeBase.codigosSelecionados;
      }

      telaBase.carregando = true;
      const retornoRelatorio = await props.servicoAPI.relatorioPadrao(tipoArquivoRelatorio, parametrosConsulta, gradeBase.filtrosAplicados, codigosSelecionados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        window.open(retornoRelatorio.link, '_blank');
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
      sincronizaTelaComponentePai();
    }

    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, telaBase.contratantesSelecionados);
      if (telaBase.preferencias !== null) {
        const preferenciaFiltros = telaBase.preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-filtros'));
        if (preferenciaFiltros !== undefined && preferenciaFiltros !== null) {
          tela.buscaAvancada.filtrosAdicionados = JSON.parse(preferenciaFiltros.valor);
          tela.buscaAvancada.chaveFiltrosAdicionados = (tela.buscaAvancada.filtrosAdicionados.length) + 1;
        }
        const preferenciaOrdenacao = telaBase.preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-ordenacao'));
        if (preferenciaOrdenacao !== undefined && preferenciaOrdenacao !== null) {
          gradeBase.ordenacaoSelecionada = JSON.parse(preferenciaOrdenacao.valor);
        }
        const preferenciaColunas = telaBase.preferencias.find(((preferencia) => preferencia.chave === 'personalizacao-colunas'));
        if (preferenciaColunas !== undefined && preferenciaColunas !== null) {
          if (preferenciaColunas.valor !== '[]' && preferenciaColunas.valor !== '') {
            const colunasDB: IColumn[] = JSON.parse(preferenciaColunas.valor);
            const colunasNovasPreferencias: IColumn[] = [];
            tela.colunas.forEach((coluna) => {
              const preferenciaCol = colunasDB.find(((colunaDB) => colunaDB.key === coluna.key));
              const colunaNova: IColumn = coluna;
              if (preferenciaCol !== undefined && preferenciaCol !== null) {
                colunaNova.visible = preferenciaCol.visible;
                colunaNova.fixed = preferenciaCol.fixed;
                colunaNova.align = preferenciaCol.align;
                colunaNova.width = preferenciaCol.width;
                colunaNova.position = preferenciaCol.position;
                colunasNovasPreferencias.push(colunaNova);
              }
            });
            tela.colunas = colunasNovasPreferencias.sort((x, y) => x.position - y.position);
          }
        } else {
          tela.colunas = props.estrutura.colunasGrade;
        }
      }
    }

    onBeforeMount(async () => {
      telaBase.carregando = true;
      telaBase.identificadorRecurso = props.estrutura.identificadorRecurso;
      telaBase.identificadorPermissao = props.estrutura.identificadorPermissao;
      telaBase.apresentarContratantes = false;

      sincronizaTelaComponentePai();
      telaBase.propriedadesConsulta = await props.servicoAPI.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        tela.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        tela.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      tela.mobile = UtilitarioDispositivo.larguraTelaMobile();
      tela.buscaAvancada.filtrosAdicionados = [];
      tela.buscaAvancada.chaveFiltrosAdicionados = 0;
      await carregarPreferencias();

      tela.paginacao.total = 100;
      tela.paginacao.showSizeChanger = true;
      tela.paginacao.pageSize = 10;
      tela.paginacao.pageSizeOptions = ['5', '10', '50', '100', '500', '1000'];
      await buscarDados();
    });

    function novoRegistro() {
      const telaOperacao = {} as ITelaOperacao;
      telaOperacao.codigoRegistro = 0;
      telaOperacao.contratanteSelecionado = telaBase.contratantesSelecionados[0];
      telaOperacao.codigoRegistroDuplicar = 0;
      telaOperacao.codigoRegistroPai = 0;
      emit('exibir-modal', telaOperacao);
    }

    function editarRegistro(codigoRegistro: number) {
      const telaOperacao = {} as ITelaOperacao;
      telaOperacao.codigoRegistro = codigoRegistro;
      telaOperacao.contratanteSelecionado = telaBase.contratantesSelecionados[0];
      telaOperacao.codigoRegistroDuplicar = 0;
      telaOperacao.codigoRegistroPai = 0;
      emit('exibir-modal', telaOperacao);
    }

    function duplicarRegistro(codigoRegistro: number) {
      const telaOperacao = {} as ITelaOperacao;
      telaOperacao.codigoRegistro = 0;
      telaOperacao.contratanteSelecionado = telaBase.contratantesSelecionados[0];
      telaOperacao.codigoRegistroDuplicar = codigoRegistro;
      telaOperacao.codigoRegistroPai = 0;
      emit('exibir-modal', telaOperacao);
    }

    function criarRegistroFilho(codigoRegistro: number) {
      const telaOperacao = {} as ITelaOperacao;
      telaOperacao.codigoRegistro = 0;
      telaOperacao.contratanteSelecionado = telaBase.contratantesSelecionados[0];
      telaOperacao.codigoRegistroDuplicar = 0;
      telaOperacao.codigoRegistroPai = codigoRegistro;
      emit('exibir-modal', telaOperacao);
    }

    async function excluirRegistro(codigo: number) {
      const retorno = await props.servicoAPI.excluir(codigo);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        tela.dados = tela.dados.filter((item: any) => item.codigo !== codigo);
        emit('registroExcluido');
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retorno.mensagem,
        });
      }
    }

    async function confirmaExclusao(objeto: any) {
      const identificacaoRegistro = props.servicoAPI.obterIdentificacaoRegistroGradeCRUD(objeto);

      Modal.confirm({
        title: props.estrutura.mensagemExclusao,
        content: `${identificacaoRegistro} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirRegistro(objeto.codigo); },
      });
    }

    async function sincronizarRegistro(codigoRegistro: number) {
      const parametrosConsulta: IParametrosConsulta = {} as IParametrosConsulta;

      parametrosConsulta.codigosSelecionados = [];

      if (codigoRegistro > 0) {
        parametrosConsulta.codigosSelecionados.push(codigoRegistro);
      }
      const resultadoBusca = await props.servicoAPI.buscaAvancada(parametrosConsulta);
      if (UtilitarioGeral.validaLista(resultadoBusca.dados)) {
        const index = tela.dados.findIndex((c) => c.codigo === codigoRegistro);
        if (index >= 0) {
          tela.dados[index] = resultadoBusca.dados[0];
        }
      }
    }

    function apresentarGuia() {
      tela.exibirGuia = !tela.exibirGuia;
    }

    function apresentarHistorico() {
      tela.exibirHistorico = !tela.exibirHistorico;
    }

    function apresentarBuscaAvancada() {
      tela.exibirPersonalizarColunas = false;
      tela.exibirBuscaAvancada = !tela.exibirBuscaAvancada;
    }

    function apresentarPersonalizarColuna() {
      tela.exibirBuscaAvancada = false;
      tela.exibirPersonalizarColunas = !tela.exibirPersonalizarColunas;
    }

    function apresentarPersonalizacaoTela() {
      tela.exibirPersonalizacaoTela = true;
    }

    async function aplicarFiltros() {
      tela.paginacao.current = 1;
      await buscarDados();
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(tela.timerRegistros);
      let totalRegistros = 100;
      tela.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        tela.paginacao.total = totalRegistros;
        tela.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarDados();
      }, 600);
    }

    async function onPageChange(current: number, pageSize: number) {
      tela.paginacao.current = current;
      tela.paginacao.pageSize = pageSize;
      await buscarDados();
    }

    async function onShowSizeChange(current: number, pageSize: number) {
      tela.paginacao.current = 1;
      tela.paginacao.pageSize = pageSize;
      await buscarDados();
    }

    async function atualizarSelecaoContratantes() {
      sincronizaTelaComponentePai();
      carregarPreferencias();
      await buscarDados();
    }

    async function salvarFiltrosBuscaAvancada() {
      await salvarPreferencias('Filtros salvos com sucesso!', 'busca-avancada-filtros',
        JSON.stringify(tela.buscaAvancada.filtrosAdicionados), telaBase.contratantesSelecionados);
    }

    async function salvarOrdenacaoBuscaAvancada() {
      await salvarPreferencias('Ordenação salva com sucesso!', 'busca-avancada-ordenacao',
        JSON.stringify(gradeBase.ordenacaoSelecionada), telaBase.contratantesSelecionados);
    }

    async function salvarPersonalizacaoColunas() {
      const colunasPersistencia: IColumn[] = []; let posicao = 0;
      tela.colunas.forEach((coluna) => {
        const colunaNova = coluna;
        colunaNova.position = posicao;
        colunasPersistencia.push(colunaNova);
        posicao += 1;
      });
      await salvarPreferencias('Personalizações salvas com sucesso!', 'personalizacao-colunas',
        JSON.stringify(colunasPersistencia.sort((c) => c.position)), telaBase.contratantesSelecionados);
    }

    async function redefinirPersonalizacaoColunas() {
      const retorno = await new ServicoSistema().redefinirPreferenciaUsuario(montaObjetoPreferencia('personalizacao-colunas', '', telaBase.contratantesSelecionados));
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        tela.colunas = props.estrutura.colunasGrade;
        apresentarMensagemSucesso('Personalizações redefinidas com sucesso!');
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    async function salvarTodasPreferencias() {
      await salvarFiltrosBuscaAvancada();
      await salvarOrdenacaoBuscaAvancada();
      await salvarPersonalizacaoColunas();
    }

    const onSelectChange = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
      sincronizaTelaComponentePai();
    };

    function obtemDataIndex(coluna: IColumn) {
      if (coluna.dataIndex !== undefined) {
        return coluna.dataIndex;
      }
      return '';
    }

    return {
      telaBase,
      gradeBase,
      tela,
      props,
      novoRegistro,
      editarRegistro,
      duplicarRegistro,
      criarRegistroFilho,
      confirmaExclusao,
      excluirRegistro,
      sincronizarRegistro,
      apresentarGuia,
      apresentarHistorico,
      apresentarBuscaAvancada,
      apresentarPersonalizarColuna,
      apresentarPersonalizacaoTela,
      aplicarFiltros,
      onPageChange,
      onShowSizeChange,
      alterarQuantidadeRegistroTotal,
      buscarDados,
      imprimir,
      onSelectChange,
      ECustomRenderRow,
      EVinculoEmpresa,
      ETipoArquivo,
      atualizarSelecaoContratantes,
      salvarFiltrosBuscaAvancada,
      salvarOrdenacaoBuscaAvancada,
      salvarPersonalizacaoColunas,
      salvarTodasPreferencias,
      redefinirPersonalizacaoColunas,
      storeSistema,
      adicionarAtalho,
      removerAtalho,
      UtilitarioMascara,
      obtemDataIndex,
    };
  },
});
